import Modal from '@/components/v1/Modal.jsx';
import Typography from '@/components/v1/Typography.jsx';
import Button from '@/components/v1/Button.jsx';
import { useForm } from '@inertiajs/react';
import Box from '@/components/v1/Box.jsx';
import { PencilSquareIcon, XCircleIcon } from '@heroicons/react/24/outline';
import LoadingOverlay from '@/components/v1/overlays/LoadingOverlay.jsx';
import BondLineForm from '@/components/v1/forms/BondLineForm.jsx';

const BondLineEditorModal = ({ onClose, investor, bond, bond_line }) => {
    const form = useForm(bond_line);

    const handleBondLineEditor = (event) => {
        event.preventDefault();
        form.submit('put', route('investors.bonds.bond-lines.update', { investor: investor.id, bond: bond.id, bond_line: bond_line.id }), {
            onSuccess: () => onClose(),
            preserveScroll: true,
        });
    };

    return (
        <Modal width={840} onClose={onClose}>
            <LoadingOverlay loading={form.processing} />

            <Typography className={'text-xl font-bold'}>Obligatie bewerken</Typography>

            <Typography>Vul onderstaand de gegevens van een obligatie in.</Typography>

            <BondLineForm className={'mt-5'} form={form} onSubmit={handleBondLineEditor} />

            <Box className={'mt-5 flex gap-5'}>
                <Button onClick={handleBondLineEditor}>
                    <PencilSquareIcon width={24} />
                    <span className={'font-bold'}>Obligatie bewerken</span>
                </Button>

                <Button className={'bg-grey-150'} onClick={onClose}>
                    <XCircleIcon width={24} />
                    <span className={'font-bold'}>Sluiten</span>
                </Button>
            </Box>
        </Modal>
    );
};

export default BondLineEditorModal;
