import TextField from '../fields/TextField.jsx';
import Typography from '../Typography.jsx';
import Box from '../Box.jsx';
import { useMemo } from 'react';
import AutocompleteField from '@/components/v1/fields/AutocompleteField.jsx';
import Select from '@/components/v1/Select.jsx';
import { startOfMonth, addMonths, format } from 'date-fns';
import { nl } from 'date-fns/locale';

const InvestorBondForm = ({ form, onSubmit, ...properties }) => {
    const data = useMemo(() => form.data, [form.data]);
    const errors = useMemo(() => form.errors, [form.errors]);

    const handleFormChange = (event) => {
        const target = event.target;
        const { name, value } = target;

        form.setData({ ...data, [name]: value });
    };

    const getStartDates = () => {
        return Array.from({ length: 3 }, (_, i) => {
            const date = startOfMonth(addMonths(new Date(), i));
            return {
                title: format(date, 'd MMMM yyyy', { locale: nl }),
                value: format(date, 'yyyy-MM-dd'),
            };
        });
    };

    return (
        <Box as={'form'} onSubmit={onSubmit} {...properties}>
            <div className={'flex flex-col gap-4'}>
                <div className={'flex flex-col gap-2'}>
                    <Typography>Propositie*</Typography>

                    <AutocompleteField name={'proposition'} value={data?.proposition} model={'Proposition'} onChange={handleFormChange} renderTitle={(option) => option.title} />

                    {errors?.proposition?.id && <Typography color={'error.base'}>{errors.proposition?.id}</Typography>}
                </div>

                <div className={'flex flex-col gap-2'}>
                    <Typography>Aantal obligaties*</Typography>

                    <TextField name={'quantity'} value={data?.quantity} error={errors?.quantity} onChange={handleFormChange} type={'number'} />

                    {errors?.quantity && <Typography color={'error.base'}>{errors.quantity}</Typography>}
                </div>

                <div className={'flex flex-col gap-2'}>
                    <Typography>Startdatum*</Typography>

                    <Select name={'start_date'} value={data?.start_date} error={errors?.start_date} options={getStartDates()} onChange={handleFormChange} />

                    {errors?.start_date && <Typography color={'error.base'}>{errors.start_date}</Typography>}
                </div>
            </div>

            <input type={'submit'} hidden />
        </Box>
    );
};

export default InvestorBondForm;
