import TextField from '../fields/TextField.jsx';
import Typography from '../Typography.jsx';
import Box from '../Box.jsx';
import { useMemo } from 'react';

const BondLineForm = ({ form, onSubmit, ...properties }) => {
    const data = useMemo(() => form.data, [form.data]);
    const errors = useMemo(() => form.errors, [form.errors]);

    const handleFormChange = (event) => {
        const target = event.target;
        const { name, value } = target;

        form.setData({ ...data, [name]: value });
    };

    return (
        <Box as={'form'} onSubmit={onSubmit} {...properties}>
            <div className={'flex flex-col gap-4'}>
                <div className={'flex flex-col gap-2'}>
                    <Typography>Referentie*</Typography>

                    <TextField name={'identifier'} placeholder={'CAP-I TR1 001'} type={'text'} value={data?.identifier} error={errors?.identifier} onChange={handleFormChange} />

                    {errors?.identifier && <Typography color={'error.base'}>{errors.identifier}</Typography>}
                </div>
            </div>

            <input type={'submit'} hidden />
        </Box>
    );
};

export default BondLineForm;
