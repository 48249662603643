import Vapor from 'laravel-vapor';

Vapor.withBaseAssetUrl(import.meta.env.VITE_VAPOR_ASSET_URL);

export default function asset(path) {
    return Vapor.asset(path);
}

export function store(file, options) {
    return Vapor.store(file, options);
}
