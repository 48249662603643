import { space } from 'styled-system';
import styled from 'styled-components';
import { useDropzone } from 'react-dropzone';
import Typography from '../Typography.jsx';
import * as Formatter from '../../../utils/old/FormatterUtil.js';
import { store } from '@/utils/VaporUtil.js';
import { useState } from 'react';

const DocumentField = ({ name, value, error, onChange, ...properties }) => {
    const [progress, setProgress] = useState(null);

    const { getRootProps, getInputProps, isDragActive, fileRejections } = useDropzone({
        accept: {
            'image/*': [],
            'application/pdf': [],
        },
        multiple: false,
        maxSize: Math.pow(1024, 2) * 15, // 15 mb
        onDrop: (files) => {
            store(files[0], {
                progress: (progress) => setProgress(Math.round(progress * 100)),
            }).then((response) => {
                setProgress(null);
                onChange({
                    target: {
                        name,
                        value: {
                            key: response.key,
                            name: files[0].name,
                            content_type: files[0].type,
                            size: files[0].size,
                        },
                    },
                });
            });
        },
    });

    return (
        <>
            <Component active={isDragActive} error={error} {...getRootProps()} {...properties}>
                <input {...getInputProps()} />

                <Typography className={'text-xl font-bold'} mt={24}>
                    {progress ? `Bezig met uploaden... (${progress}%)` : value ? value.name : 'Document selecteren'}
                </Typography>

                {!progress && <Typography color={'grey.9'}>{value ? Formatter.bytes(value.size) : 'png, jpeg, jpg'}</Typography>}
            </Component>

            {fileRejections.length > 0 && <Typography color={'error.base'}>Bestand is te groot. Maximale grootte is 4MB.</Typography>}
        </>
    );
};

const Component = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 120px 24px;
    width: 100%;
    background: ${({ theme: { colors } }) => colors.background.variant};
    border: 1px solid ${({ theme, error }) => (error ? theme.colors.error.base : theme.colors.grey[1])};
    border-radius: 0.5rem;
    cursor: pointer;
    transition: filter ease-in-out 100ms;

    ${space}
    &:hover {
        filter: brightness(95%);
    }
`;

export default DocumentField;
