import Modal from '@/components/v1/Modal.jsx';
import Typography from '@/components/v1/Typography.jsx';
import Button from '@/components/v1/Button.jsx';
import { useForm } from '@inertiajs/react';
import Box from '@/components/v1/Box.jsx';
import { PencilSquareIcon, XCircleIcon } from '@heroicons/react/24/outline';
import InvestorBondForm from '@/components/v1/forms/InvestorBondForm.jsx';
import LoadingOverlay from '@/components/v1/overlays/LoadingOverlay.jsx';

const InvestorApplicationModal = ({ onClose, presets, investor }) => {
    const form = useForm();

    const handleApplicationEditor = (event) => {
        event.preventDefault();
        form.submit('post', route('investors.bonds.store', { investor: investor.id }), {
            onSuccess: () => onClose(),
            preserveScroll: true,
        });
    };

    return (
        <Modal width={840} onClose={onClose}>
            <LoadingOverlay loading={form.processing} />

            <Typography className={'text-xl font-bold'}>Deelname toevoegen</Typography>

            <Typography>Vul onderstaande velden in om de (nieuwe) deelname compleet te maken.</Typography>

            <InvestorBondForm className={'mt-5'} form={form} onSubmit={handleApplicationEditor} />

            <Box className={'mt-5 flex gap-5'}>
                <Button onClick={handleApplicationEditor}>
                    <PencilSquareIcon width={24} />
                    <span className={'font-bold'}>Deelname bevestigen</span>
                </Button>

                <Button className={'bg-grey-150'} onClick={onClose}>
                    <XCircleIcon width={24} />
                    <span className={'font-bold'}>Sluiten</span>
                </Button>
            </Box>
        </Modal>
    );
};

export default InvestorApplicationModal;
